import React, { FC, useCallback } from 'react';
import { useEffect } from 'react';
import { io } from 'socket.io-client';
import WSContext from '../contexts/WSContext';
import { useDispatch } from '../../../redux/store';
import {
  onMessagesCreated,
  onMessageUpdated,
  onConversationUpdated,
  onConversationCreated,
  onConversationDeleted,
} from '../../../redux/slices/chat';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import {
  useInvalidateTotalUnreadMessages,
  useTotalUnreadMessages,
} from '@subflow/data-access';

const socket = io(process.env.NEXT_PUBLIC_SUBFLOW_API_ENDPOINT, {
  transports: ['websocket'],
  secure: true,
  autoConnect: false,
  withCredentials: true,
});

const WSProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated, session } = useSessionAuth();
  useTotalUnreadMessages(!isAuthenticated);
  const { invalidateTotalUnreadMessages } = useInvalidateTotalUnreadMessages();
  useEffect(() => {
    if (isAuthenticated) {
      console.log('[websocket] Connecting..');
      socket.connect();
      console.log('[websocket] Connected.');
      return () => {
        socket.disconnect();
      };
    }
  }, [isAuthenticated]);

  useEffect(() => {
    //if sesssion has changed - token refreshed, we have to reconnect
    if (isAuthenticated && session) {
      socket.disconnect();
      socket.connect();
    }
  }, [isAuthenticated, session]);

  useEffect(() => {
    if (isAuthenticated) {
      const messageCreated = (data) => {
        dispatch(onMessagesCreated(data));
        invalidateTotalUnreadMessages();
      };

      socket.on('message_created', messageCreated);

      const messageUpdated = (data) => {
        dispatch(onMessageUpdated(data));
        invalidateTotalUnreadMessages();
      };

      socket.on('message_updated', messageUpdated);

      const conversationCreated = (data) => {
        dispatch(onConversationCreated(data));
        invalidateTotalUnreadMessages();
      };

      socket.on('conversation_created', conversationCreated);

      const conversationUpdated = (data) => {
        dispatch(onConversationUpdated(data));
        invalidateTotalUnreadMessages();
      };
      socket.on('conversation_updated', conversationUpdated);

      const conversationDeleted = (data) => {
        dispatch(onConversationDeleted(data));
        invalidateTotalUnreadMessages();
      };

      socket.on('conversation_deleted', conversationDeleted);

      return () => {
        socket.off('message_created', messageCreated);
        socket.off('message_updated', messageUpdated);
        socket.off('conversation_created', conversationCreated);
        socket.off('conversation_updated', conversationUpdated);
        socket.off('conversation_deleted', conversationDeleted);
      };
    }
  }, [dispatch, invalidateTotalUnreadMessages, isAuthenticated]);
  return (
    <WSContext.Provider
      value={{
        wsClient: socket,
      }}
    >
      {children}
    </WSContext.Provider>
  );
};

export default WSProvider;
