import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useBillingPortalSession, useMerchant } from '@subflow/data-access';
import { useSnackbar } from 'notistack';
import { InviteMemberRequestDtoRoleEnum } from '@subflow/api-client';
import { useCanAccess } from '@subflow-frontend/guards/RoleBasedGuard';

export default function useCommunityMembersCounter() {
  const { data: merchant } = useMerchant();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    communityMembersLimit: 0,
    communityMembersUsed: 0,
    progress: 0,
  });

  const accessGranted = useCanAccess([
    InviteMemberRequestDtoRoleEnum.Owner,
    InviteMemberRequestDtoRoleEnum.Admin,
  ]);

  const { data: billingPortalUrl } = useBillingPortalSession(accessGranted);

  const progress =
    merchant?.communityMembersUsed && merchant?.communityMembersLimit
      ? (merchant?.communityMembersUsed * 100) / merchant.communityMembersLimit
      : 0;

  useEffect(() => {
    if (progress >= 100 && billingPortalUrl) {
      enqueueSnackbar(
        <Box>
          You have used up your free community members seats. You need to{' '}
          <a href={billingPortalUrl}>upgrade</a> to add more.
        </Box>,
        {
          variant: 'error',
          persist: true,
        }
      );
    }
    if (merchant) {
      setState({
        communityMembersLimit: merchant.communityMembersLimit,
        communityMembersUsed: merchant.communityMembersUsed,
        progress,
      });
    }
  }, [merchant, progress, billingPortalUrl]);

  return state;
}
