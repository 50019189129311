import { useState } from 'react';
import { TablePaginationProps } from '@mui/material/TablePagination';
type onPageChangeParams = Parameters<TablePaginationProps['onPageChange']>;

interface PaginationInfo {
  limit: number;
  page: number;
  search: string;
}
export const limitOptions = [15, 30, 45];
const initPage = 0;
const initStateValue = {
  page: initPage,
  limit: limitOptions[0],
  search: '',
};

export function usePagination(defaultPagination?: PaginationInfo) {
  const [pagination, setPagination] = useState<PaginationInfo>(
    defaultPagination ? defaultPagination : initStateValue
  );

  const onPaginationPageChange = (
    event: onPageChangeParams[0],
    newPage: onPageChangeParams[1]
  ) => {
    setPagination((prev) => ({
      search: prev.search,
      page: newPage,
      limit: prev.limit,
    }));
  };

  const onPaginationRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prev) => ({
      search: prev.search,
      page: initPage,
      limit: parseInt(event.target.value, 10),
    }));
  };

  const onSearchChange = (value: string) => {
    setPagination((prev) => ({
      search: value,
      page: initPage,
      limit: prev.limit,
    }));
  };

  return {
    pagination,
    onPaginationPageChange,
    onPaginationRowsPerPageChange,
    onSearchChange,
  };
}
