export const preloadAssets = (urls: string[]) => {
  const promises = urls.map((url) => {
    const extension = url.split('.').pop();
    switch (extension) {
      case 'json':
        console.log('preloadAssets: json', url);
        return fetch(url).then((response) => response.json());
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'avif':
      case 'svg':
        console.log('preloadAssets: image', url);
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(url);
          image.onerror = () => reject(url);
          image.src = url;
        });
      default:
        console.log('preloadAssets: unsupported', url);
        return Promise.reject(new Error(`Unsupported file extension: ${url}`));
    }
  });

  return Promise.allSettled(promises);
};
