import { createContext } from 'react';
import { Socket } from 'socket.io-client';

interface WSContextProps {
  wsClient: Socket;
}

const WSContext = createContext<WSContextProps>({
  wsClient: undefined,
});

export default WSContext;
