import { useEffect, useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

export const isToday = (date: Date): boolean => {
  return new Date().toDateString() === new Date(date).toDateString();
};

export default function useLastActivity(time: Date) {
  const [lastActivity, setLastActivity] = useState(
    formatDistanceToNowStrict(time, {
      addSuffix: false,
    })
  );

  useEffect(() => {
    if (isToday(time)) {
      setLastActivity((lastActivity) =>
        formatDistanceToNowStrict(new Date(time), {
          addSuffix: false,
        })
      );

      const interval = setInterval(() => {
        console.log('Logs every minute');

        setLastActivity((lastActivity) =>
          formatDistanceToNowStrict(time, {
            addSuffix: false,
          })
        );
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [time]);

  return lastActivity;
}
