import { useCallback, useRef, useState } from 'react';

export default function useSelection(allIds: string[], singleSelect = false) {
  const [selected, setSelected] = useState<string[]>([]);
  const selectedRef = useRef<string[]>([]);

  const handleSelectedChange = useCallback((newSelected: string[]) => {
    selectedRef.current = newSelected;
    setSelected(newSelected);
  }, []);
  const handleSelectAllClick = (checked: boolean) => {
    if (!singleSelect) {
      if (checked) {
        handleSelectedChange(allIds);
        return;
      }
      handleSelectedChange([]);
    }
  };

  const handleUnselectAll = () => {
    handleSelectedChange([]);
  };

  const handleSelectClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (singleSelect) {
      if (selectedIndex === -1) {
        newSelected = [id];
      } else {
        newSelected = [];
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    }
    handleSelectedChange(newSelected);
  };

  return {
    selected,
    selectedRef,
    handleSelectAllClick,
    handleSelectClick,
    handleUnselectAll,
    setSelected: handleSelectedChange,
  };
}
