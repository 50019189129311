import { ReactNode, useState, useEffect } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
import { useSessionAuth } from '@subflow-frontend/hooks/useSessionAuth';
import { getOrganizationId } from '@subflow/data-access';
import { InviteMemberRequestDtoRoleEnum } from '@subflow/api-client';
import { useRouter } from 'next/router';
import { PATH_DASHBOARD } from '@subflow-frontend/routes/paths';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  allowedRoles: InviteMemberRequestDtoRoleEnum[];
  isPage?: boolean;
  showWarning?: boolean;
  children: ReactNode | string;
};

type RoleBasedGuardWithRedirectProp = {
  allowedRoles: InviteMemberRequestDtoRoleEnum[];
  children: ReactNode | string;
  redirectPath?: string;
};

export const useCanAccess = (
  allowedRoles: InviteMemberRequestDtoRoleEnum[]
) => {
  const [accessGranted, setAccessGranted] = useState(false);

  const { session } = useSessionAuth();
  const currentOrgId = getOrganizationId();

  useEffect(() => {
    setAccessGranted(
      canAccess(currentOrgId, allowedRoles, session?.organizations)
    );
  }, [currentOrgId, session]);

  return accessGranted;
};

const canAccess = (
  currentOrgId: string,
  allowedRoles: InviteMemberRequestDtoRoleEnum[],
  organizations = {}
) => {
  const currentOrgRoles = organizations[currentOrgId]?.roles;

  const canAccess =
    currentOrgId &&
    Object.keys(organizations).includes(currentOrgId) &&
    allowedRoles.some((role) => currentOrgRoles.includes(role));

  return canAccess;
};

export default function RoleBasedGuard({
  allowedRoles,
  children,
  isPage = false,
  showWarning = false,
}: RoleBasedGuardProp) {
  const { session } = useSessionAuth();
  const currentOrgId = getOrganizationId();
  const accessGranted = canAccess(
    currentOrgId,
    allowedRoles,
    session?.organizations
  );

  if (!accessGranted) {
    return showWarning ? (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this {isPage ? 'page' : 'info'}
        </Alert>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}

export const RoleBasedGuardWithRedirect = ({
  allowedRoles,
  children,
  redirectPath = PATH_DASHBOARD.root,
}: RoleBasedGuardWithRedirectProp) => {
  const [accessGranted, setAccessGranted] = useState(false);
  const { session } = useSessionAuth();
  const { push } = useRouter();

  const currentOrgId = getOrganizationId();

  useEffect(() => {
    const accessGranted = canAccess(
      currentOrgId,
      allowedRoles,
      session?.organizations
    );
    if (accessGranted) {
      setAccessGranted(true);
    } else {
      push(redirectPath);
    }
  }, [currentOrgId, session]);

  if (!accessGranted) {
    return null;
  }

  return <>{children}</>;
};
