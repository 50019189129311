import React from 'react';
import Avatar, {
  Props as AvatarProps,
} from '@subflow-frontend/components/Avatar';
import createAvatar from '@subflow-frontend/utils/createAvatar';

interface CustomAvatarProps extends AvatarProps {
  profileName?: string;
  profileImage?: string;
  profileLoaded?: boolean;
}

export const CustomAvatar: React.FC<CustomAvatarProps> = (props) => {
  const { profileLoaded, profileName, profileImage, ...other } = props;
  return (
    <>
      <Avatar
        src={profileImage}
        color={
          profileLoaded && !profileImage
            ? createAvatar(profileName).color
            : undefined
        }
        alt={profileName}
        {...other}
      >
        {createAvatar(profileName).name}
      </Avatar>
    </>
  );
};
