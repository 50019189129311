import { useContext } from 'react';
import WSContext from '../contexts/WSContext';

const useWS = () => {
  const { wsClient } = useContext(WSContext);

  return {
    wsClient,
  };
};

export default useWS;
