import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar as MUIAvatar, AvatarProps } from '@mui/material';
import { NextImageWithSkeleton } from '@subflow-frontend/components/image/NextImageWithSkeleton';

// ----------------------------------------------------------------------

type AvatarColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

// ----------------------------------------------------------------------

export interface Props extends AvatarProps {
  color?: AvatarColor;
}

const Avatar = forwardRef<HTMLDivElement, Props>(
  ({ color = 'default', children, sx, src, alt, ...other }, ref) => {
    const theme = useTheme();

    const avatarStyles: AvatarProps['sx'] = {
      ...(color !== 'default' && {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
      }),
      ...(src && {
        backgroundColor: 'transparent',
      }),
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...sx,
    };

    return (
      <MUIAvatar ref={ref} sx={avatarStyles} {...other}>
        {src ? (
          <NextImageWithSkeleton
            src={src}
            alt={alt}
            fill
            style={{ objectFit: 'cover', borderRadius: '50%' }}
          />
        ) : (
          children
        )}
      </MUIAvatar>
    );
  }
);

export default Avatar;
