import { useState } from 'react';
import { TablePaginationProps } from '@mui/material/TablePagination';
type onPageChangeParams = Parameters<TablePaginationProps['onPageChange']>;
import { limitOptions } from './usePagination';

interface PaginationInfo {
  limit: number;
  page: number;
  search: string;
  nextCursor?: string;
  previousCursor?: string;
}
const initPage = 0;
const initStateValue = {
  page: initPage,
  limit: limitOptions[0],
  search: '',
};

export function useCursorPagination() {
  const [pagination, setPagination] = useState<PaginationInfo>(initStateValue);

  const onPaginationPageChange = (
    event: onPageChangeParams[0],
    newPage: onPageChangeParams[1],
    nextCursor: string,
    previousCursor: string
  ) => {
    setPagination((prev) => {
      const direction = newPage > prev.page ? 'next' : 'previous';
      return {
        search: prev.search,
        page: newPage,
        limit: prev.limit,
        ...(direction === 'next' && { nextCursor }),
        ...(direction === 'previous' && { previousCursor }),
      };
    });
  };

  const onPaginationRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPagination((prev) => ({
      search: prev.search,
      page: initPage,
      limit: parseInt(event.target.value, 10),
    }));
  };

  const onSearchChange = (value: string) => {
    setPagination((prev) => ({
      search: value,
      page: initPage,
      limit: prev.limit,
    }));
  };

  return {
    pagination,
    onPaginationPageChange,
    onPaginationRowsPerPageChange,
    onSearchChange,
  };
}
