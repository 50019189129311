import { Attachment } from '@subflow-frontend/@types/messaging';
import { UpdateConversationRequestDto } from '@subflow/api-client';
import useWS from './useWS';
import { useCallback } from 'react';

const useWSConversation = () => {
  const { wsClient } = useWS();

  const sendMessage = async (
    conversationId: string,
    message: string,
    attachments: Attachment[]
  ): Promise<void> => {
    if (wsClient.connected) {
      wsClient.emit('create_message', {
        message,
        attachments,
        conversationId,
      });
    }
  };

  const updateConversation = useCallback(
    async (
      conversationId: string,
      request: UpdateConversationRequestDto
    ): Promise<void> => {
      if (wsClient?.connected) {
        wsClient.emit('update_conversation', {
          conversationId,
          request,
        });
      }
    },
    [wsClient]
  );

  return {
    sendMessage,
    updateConversation,
    wsReady: wsClient?.connected,
  };
};

export default useWSConversation;
