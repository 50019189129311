import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import NextImage from 'next/future/image';
import { ImageProps } from 'next/dist/client/future/image';

export const NextImageWithSkeleton: React.FC<ImageProps> = (props) => {
  const [showSkeleton, setShowSkeleton] = useState(true);
  const { src } = props;
  useEffect(() => {
    setShowSkeleton(true);
  }, [src]);
  const handleLoadingComplete: ImageProps['onLoadingComplete'] = (img) => {
    setShowSkeleton(false);
  };

  return (
    <>
      {showSkeleton && (
        <Skeleton
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            borderRadius: 'inherit',
          }}
          width={'100%'}
          height={'100%'}
          variant="rectangular"
        />
      )}
      <NextImage {...props} onLoadingComplete={handleLoadingComplete} />
    </>
  );
};
