import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import NextLink from 'next/link';
import { forwardRef } from 'react';

interface Props extends BoxProps {
  disabledLink?: boolean;
  logoUrl?: string;
}

const Logo = forwardRef<any, Props>(
  ({ disabledLink = false, logoUrl, sx }, ref) => {
    const theme = useTheme();
    const PRIMARY_MAIN = theme.palette.primary.main;

    const logo = logoUrl ? (
      <Box
        component="img"
        ref={ref}
        sx={{ width: 50, height: 50, ...sx }}
        src={logoUrl}
      ></Box>
    ) : (
      <Box ref={ref} sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 477.86 561.21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Subflow</title>
          <g
            fill={PRIMARY_MAIN}
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          >
            <polygon points="477.86 414.91 377.71 379.01 358.69 346.02 328.72 294.02 317.09 273.84 235.85 132.92 214.63 169.05 293.57 306.01 295.85 309.97 324.7 360.01 337.46 382.15 297.52 450.1 272.92 491.96 232.22 561.21 190.61 489.04 119.37 365.44 109.38 348.12 94.15 374.05 0 406.16 72.11 283.46 78.91 271.9 112.81 214.2 150.09 278.86 163.58 302.25 186.75 342.45 231.31 419.78 255.93 377.92 227.47 328.56 224.99 324.27 174.69 237 137.41 172.34 177.35 104.39 198.59 68.26 238.7 0 275.98 64.66 357.21 205.58 395.12 271.38 398.81 277.77" />
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return <NextLink href="/">{logo}</NextLink>;
  }
);

export default Logo;
