import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number) {
  return numeral(number).format('0.0%');
}
type InputValue = string | number | null;

export function fPercent100(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}
function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.0a').replace('.0', '');
}
/**
 * @Deprecated - returns incorrect number for MB, GB, etc
 */
export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function fData2(number: string | number) {
  const num = typeof number === 'string' ? parseInt(number, 10) : number;

  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let unitIndex = 0;
  let numToFormat = num;

  while (numToFormat >= 1024 && unitIndex < units.length - 1) {
    numToFormat /= 1024;
    unitIndex++;
  }

  return numeral(numToFormat).format('0,0.0') + ' ' + units[unitIndex];
}
