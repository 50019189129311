import { Box } from '@mui/material';
import { Props as AvatarProps } from '../Avatar';
import {
  MerchantResponseDto,
  SubscriberOrgResponseDto,
} from '@subflow/api-client';
import { CustomAvatar } from '@subflow-frontend/components/avatars/CustomAvatar';

interface MerchantAvatarProps extends AvatarProps {
  merchant?:
    | Pick<MerchantResponseDto, 'businessName' | 'logo'>
    | Pick<SubscriberOrgResponseDto, 'businessName' | 'logo'>;
}

export default function OrgAvatar({ merchant, ...other }: MerchantAvatarProps) {
  return (
    <Box>
      <CustomAvatar
        profileName={merchant?.businessName}
        profileImage={merchant?.logo}
        profileLoaded={!!merchant}
        {...other}
      />
    </Box>
  );
}
